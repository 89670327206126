.admin-option {
    font-size: 0.7rem;
    cursor: pointer;
    margin-right: 0.3rem;
    text-decoration: none;
    color: black;
}

.admin-option:hover {
    color: var(--main-color);
    text-decoration: underline;
}