@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
}

.App, html, #root, body {
  /*   background-color: black;  for debugging */
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

:root {
  --main-bg-color: #f2f2f2;
  --alt-bg-color: #ffffff;
  --main-font: 'Roboto', sans-serif;
  --main-color: #0571cb;
  --main-nav-color: #ffffff;
  --main-border-color: #e0e0e0;

  --confirm-bg-color: #06cb05;

  --secondary-font: 'Montserrat', sans-serif;
  
  --main-text-color: #6b6b6b;
  --alt-text-color: #ffffff;
  
  --card-surface: #ffffff;
  --box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  --error-color: #e70000;

  --main-border: 1px solid var(--main-border-color);
}

.hidden {
  display: none !important;
}

.closeButton::after{
  content: '\00d7';
  position: absolute;
  top: 5px;
  right: 10px;
}

.menuItem:hover {
  background-color: #fafafa;
}
.sidebar-icon {
    font-size: 1.2rem;
    color: var(--main-text-color);
}

.sidebar-link {
    display: flex;
    padding: .5rem;
}

.sidebar-link:hover {
    background-color: #f3f3f3;
}

.closeButton p {
    font-size: 0.75rem;
}
.nav-logo {
    width: 15%;
    min-width: 150px
}

@media only screen and (max-width: 600px) {}
.collapsed {
    max-height: 200px;
}

.expanded {
    max-height: 1100px;
}
.admin-option {
    font-size: 0.7rem;
    cursor: pointer;
    margin-right: 0.3rem;
    text-decoration: none;
    color: black;
}

.admin-option:hover {
    color: var(--main-color);
    text-decoration: underline;
}
.export-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    align-self: flex-end;
    padding: 5px;
    color: var(--alt-text-color);
    text-decoration: none;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    width: 100%;
    max-width: 190px;
    cursor: pointer;
    font-weight: 500;
}

.export-link:hover {
    opacity: 0.8;
}

.export-link:active {
    box-shadow: none;
}
