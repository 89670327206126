.sidebar-icon {
    font-size: 1.2rem;
    color: var(--main-text-color);
}

.sidebar-link {
    display: flex;
    padding: .5rem;
}

.sidebar-link:hover {
    background-color: #f3f3f3;
}

.closeButton p {
    font-size: 0.75rem;
}