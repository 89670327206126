.export-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    align-self: flex-end;
    padding: 5px;
    color: var(--alt-text-color);
    text-decoration: none;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    width: 100%;
    max-width: 190px;
    cursor: pointer;
    font-weight: 500;
}

.export-link:hover {
    opacity: 0.8;
}

.export-link:active {
    box-shadow: none;
}